import React from 'react';
import './TimelineVert.css';
import Layout from "../../pages"
import { Link } from "gatsby"
import { Separator } from "../course/course-ui-components/SeparatorPadding"

export const TimeLineVert = () => {
  return (
    <section id="timeline">
      <div className="text-box-title center">RICHIEDI UN CORSO PERSONALIZZATO</div>
      <Separator size="xl"/>
      <div className="demo-card-wrapper">
        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>01</span>
            </div>
            <h2><span className="small">RICHIESTA CONTATTO E</span>PREVENTIVO</h2>
          </div>
          <div className="body">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus

              <Link to="forms/richiedi-preventivo-corso/?subject=1">
                Form di Contatto
              </Link>
            </p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>02</span>
            </div>
            <h2><span className="small">PERSONALIZZAZIONE DEL</span>PROGRAMMA</h2>
          </div>
          <div className="body">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus
              consequatur, dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus
              consequatur, laudantium odio dolorum laboriosam.</p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>03</span>
            </div>
            <h2><span className="small">Erogazione</span> LEZIONI</h2>
          </div>
          <div className="body">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus
              consequatur, laudantium odio dolorum laboriosam.</p>
          </div>
        </div>

        <div className="demo-card demo-card--step1">
          <div className="head">
            <div className="number-box">
              <span>04</span>
            </div>
            <h2><span className="small">ASSISTENZA e SUPPORTO</span> POST-CORSO</h2>
          </div>
          <div className="body">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus
              consequatur, laudantium odio dolorum laboriosam.</p>
          </div>
        </div>



      </div>
    </section>
  )
};

