// import separator from "../../../images/separator-2@1x.svg"
import React from "react"

const SIZES = {
  xs: 5,
  sm: 10,
  md: 20,
  lg: 40,
  xl: 60,
  xxl: 120
}


export const Separator = ({ size = 'xs'}) => {
  const styles = {
    margin: `${SIZES[size]}px 0`,
    backgroundColor: 'red'
  }

  return (
    <div style={styles}></div>
  )
}
