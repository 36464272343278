import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import Layout from "../components/layout"
/*import Image from "../components/image"*/
import SEO from "../components/seo"
import { SeparatorWave } from "../components/course/separator-wave/SeparatorWave"
import { Carousel } from "react-responsive-carousel"
import { Separator } from "../components/course/course-ui-components/SeparatorPadding"
import { Footer } from "../components/shared/Footer"
import { faChartBar, faClock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { COURSE_INFO as A_NGRX } from "./corso-angular-ngrx"
import { COURSE_INFO as A_CC } from "./corso-angular-core-concepts"
import { COURSE_INFO as A_FORMS } from "./corso-angular-forms"
import { TimeLineVert } from "../components/shared/TimelineVert"
import { CourseInstructorProfile } from "../components/course/course-instructor-profile/CourseInstructorProfile"


export const COURSES = {
  angular: {
    title: 'ANGULAR',
    items: [
      ...A_CC,
      ...A_FORMS,
      ...A_NGRX,
      ...A_NGRX,
      ...A_NGRX,
    ]
  },
  react: {
    title: 'REACT',
    items: [
      ...A_CC,
      ...A_NGRX,
    ]
  }
}
export default class IndexPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            logo_ngrx_angular: file(relativePath: { eq: "logo-ngrx-angular.png" }) { ...thumb },
            angular_core_concepts: file(relativePath: { eq: "courses/angular-core-concepts/angular-core-concepts-ts.png" }) { ...thumb },
            angular_forms: file(relativePath: { eq: "courses/angular-forms/angular-forms.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "home/header1.png" }) { ...imgTrainingBig },

            review_daniela_ferrante: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_daniela_ferrante.png" }) { ...imgTrainingBig },
            review_zack_nero: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_zack_nero.png" }) { ...imgTrainingBig },
            review_marco_brunet: file(relativePath: { eq: "courses/angular-fundamentals/reviews/review_marco_brunet.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },
            profile: file(relativePath: { eq: "courses/_general/profile/profile-round-gde-1.png" }) { ...thumb },
            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <Compo {...this.props}  data={data} /> }
      />
    )
  }
}


const Compo = (props) => (
  <Layout>
    <SEO title="Home" />
{/*

    <div className="container-fluid">
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        emulateTouch
        autoPlay
        stopOnHover
        interval={3000}
        showStatus={false}
        show
        showThumbs={false}
        showIndicators={false}
      >
        <div>
          <img src={props.data.carouselIntro1.childImageSharp.fluid.src} alt=""/>
          <p className="legend">
            bla bla
          </p>
        </div>

      </Carousel>

    </div>

    <Separator size="xxl"/>

*/}


    <SeparatorWave bg1="#CB2B38"  bg2="#B32435" />

    <div className="container-big">

      <div className="text-header-main-title center">CORSI ANGULAR</div>
      <Separator size="xl"/>

      <div className="row">
        <CourseRow {...props} row={COURSES.angular} />
      </div>

      <Separator size="lg"/>

    </div>

    <SeparatorWave bg1="#61DAFB"  bg2="#30B3D7" />

    <div className="container-big">
      <div className="text-header-main-title center">CORSI REACT</div>
      <Separator size="xl"/>

      <div className="row">
        <CourseRow {...props} row={COURSES.react} />
      </div>

      <Separator size="xl"/>


    </div>

    <div style={{ backgroundColor: '#222', width: '100%', color: 'white', padding: '60px 0'}}>
      <div className="container-big">

        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-8 text-paragraph-title">
            <br/>Centinaia di aziende hanno già scelto i miei corsi <br/><br/>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="text-box-title">
              2500+ STUDENTI<br/>
              200+ SOCIETÀ<br/>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="container-big">

      <Separator size="xxl"/>

      <CourseInstructorProfile {...props}/>

    </div>



    <TimeLineVert />
  {/*  <li><Link to="/corso-angular-core-concepts/">Corso Angular Core Concepts</Link></li>
    <li><Link to="/corso-angular-ngrx/">Video Corso NGRX</Link></li>

*/}
    <Footer />

  </Layout>
)

const CourseRow = (props) => {
  return (
    props.row.items.map(item => {
      return <CourseItem {...props} {...item}/>
    })
  )
}


const CourseItem = props => {
  return (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <Separator size="md"/>

      <div className="text-title-md center"
             dangerouslySetInnerHTML={{ __html: props.header.shortTitle }}
        />

        <Separator size="sm"/>
        <div className="row">
          <div className="col-xs right">
            <img src={props.data[props.header.image].childImageSharp.fluid.src}  style={{maxHeight: 90}}/>
          </div>
          <div className="col-xs">
            <Separator size="md" />
            <FontAwesomeIcon icon={faClock} /> {props.header.duration}
            <Separator size="xs" />
            <FontAwesomeIcon icon={faChartBar} /> {props.header.difficulty}
          </div>
        </div>

        <Separator size="sm"/>

        <div>
          {props.header.subtitle}
        </div>
        <Separator size="sm"/>

        <div
          className="show-on-xs"
          style={{border: '1px dashed #ccc'}}></div>

       {/* <div className="center">
          <button className="button button1 xs">
            PROGRAMMA
          </button>
        </div>*/}


    </div>
  )
}

